import cashbear from '../assets/cashbear.jpg';
import cashgorilla from '../assets/cashgorilla.png';
import cashmamba from '../assets/cashmamba.webp';
import cashrabbit from '../assets/cashrabbit.jpg';
import cashsamurai from '../assets/cashsamurai.webp';
import cashwarrior from '../assets/cashwarrior.webp';
import cashzebra from '../assets/cashzebra.png';
import mathschamp from '../assets/mathschamp.webp';
import rewardchamp from '../assets/rewardchamp.png';
import wolfearn from '../assets/wolfearn.webp';
import { appRedirectionSchemas } from './appRedirection';

// Array of game details
export const latestGames = [
  //! Cash Zebra
  {
    name: 'Cash Zebra',
    description: 'Earn rewards with Cash Zebra! Complete tasks, Easy Surveys, and enjoy rewards!',
    image: cashzebra,
    referralCode: 'sudeep',
  },

  //! Cash Mamba
  {
    name: 'Cash Mamba',
    description: 'Play games and complete simple surveys to earn with Cash Mamba!',
    image: cashmamba,
    referralCode: 'FRBxBp',
  },

  //! Maths Champ
  {
    name: 'Maths Champ',
    description: 'Elevate your problem-solving skills and get points!',
    image: mathschamp,
    referralCode: 'lYFA98',
  },

  //! Wolf Earn
  {
    name: 'Wolf Earn',
    description: 'Enjoy and earn rewards with Wolf Earn!',
    image: wolfearn,
    referralCode: 'nMHYdw',
  },

  //! Cash Samurai
  {
    name: 'Cash Samurai',
    description: 'Play and earn rewards with excitement!',
    image: cashsamurai,
    referralCode: 'bhy8zY',
  },

  //! Cash Warrior
  {
    name: 'Cash Warrior',
    description: 'Earn rewards by playing games and completing surveys with Cash Warrior!',
    image: cashwarrior,
    referralCode: '8D2saA',
  },

  //! Cash Bear
  {
    name: 'Cash Bear',
    description: 'Earn rewards by playing games and completing surveys with Cash Bear!',
    image: cashbear,
    referralCode: 'sudeep',
  },

  //! Cash Gorilla
  {
    name: 'Cash Gorilla',
    description: 'Cash Gorilla is your go-to app for earning rewards via enjoyable tasks, games, and surveys.',
    image: cashgorilla,
    referralCode: 'sudeep',
  },

  //! Reward Champ
  {
    name: 'Reward Champ',
    description: 'Earn rewards by playing games and completing surveys with Reward Champ!',
    image: rewardchamp,
    referralCode: 'sudeep',
  },

  //! Cash Rabbit
  {
    name: 'Cash Rabbit',
    description: 'Earn rewards by playing games and completing surveys with Cash Rabbit!',
    image: cashrabbit,
    referralCode: 'sudeep',
  },
].map(game => {
  const currentApp = game.name.toLowerCase().replace(/\s+/g, '');
  return {
    ...game,
    link: `${appRedirectionSchemas[currentApp]}${game.referralCode}`,
  };
});
