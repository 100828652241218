import { availableApps } from './availableApps';

export const appRedirectionSchemas = {
  wolfearn: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.wolfearn),
  cashsamurai: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashsamurai),
  cashmamba: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashmamba),
  mathschamp: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.mathschamp),
  cashzebra: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashzebra),
  cashbear: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashbear),
  cashrabbit: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashrabbit),
  cashwarrior: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashwarrior),
  cashgorilla: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashgorilla),
  rewardchamp: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.rewardchamp),
}